import React from "react"
import PropTypes from "prop-types"
import DynamicImage from "../utils/dynamicimage"

export default function NewsletterItem({
  issue_link,
  alt,
  image_fluid,
  image_src,
  publishing_date,
  node_id,
  tags,
  title_text,
  excerpt_text,
  background_color,
  border_active,
  shadow_active,
  rounded_active = true,
  animations_active = true,
  additionalClassNames = "",
}) {
  return (
    <a
      key={node_id}
      className={`focus:outline-none ${additionalClassNames}`}
      href={issue_link}
      target="_blank"
      rel="noreferrer"
      data-sal={animations_active ? "slide-up" : null}
      data-sal-duration={animations_active ? "1000" : null}
      data-sal-delay={
        animations_active ? Math.floor(Math.random() * 500) : null
      }
      data-sal-easing={animations_active ? "ease" : null}
    >
      <div
        className={
          "post px-0 bg-white py-0 mb-3 col-span-1 flex flex-col h-full hover:bg-gray-100 transition-all duration-500" +
          // (additional_styles ? ` ${additional_styles}` : "") +
          (background_color ? ` ${background_color}` : "") +
          (border_active ? " border border-gray-800" : "") +
          (rounded_active ? " rounded-md" : " py-2 px-2") +
          (shadow_active ? " shadow-md" : "")
        }
      >
        <div className="w-full h-48 overflow-hidden">
          <DynamicImage
            className="min-w-full min-h-full"
            alt={alt}
            placeholderClassName="w-full object-cover object-center"
            style={{ maxHeight: "100%" }}
            imgStyle={{
              objectFit: "cover",
              width: "100%",
              objectPosition: "50% 50%",
              maxHeight: "100%",
            }}
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={image_fluid}
            src={image_src}
          />
        </div>
        <div className="max-w-md pt-3 min-w-md">
          <h2 className="pb-1 text-xl font-medium leading-tight text-gray-900 sm:leading-tight lg:leading-tight sm:text-2xl lg:pb-1">
            {title_text}
          </h2>
          <p className="text-xs font-light text-gray-800 sm:text-sm lg:text-base">
            {excerpt_text}
          </p>
          {/* <p className="text-cb-500">{publishing_date}</p> */}
        </div>
      </div>
    </a>
  )
}

NewsletterItem.propTypes = {
  issue_link: PropTypes.string,
  image_fluid: PropTypes.object,
  image_src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  publishing_date: PropTypes.string,
  node_id: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title_text: PropTypes.string.isRequired,
  excerpt_text: PropTypes.string,
  background_color: PropTypes.string,
  border_active: PropTypes.bool,
  shadow_active: PropTypes.bool,
  animation_active: PropTypes.bool,
  rounded_active: PropTypes.bool,
  flippedProps: PropTypes.object,
  additionalClassNames: PropTypes.string,
}
