import "preact/debug"
// import { Profiler } from "react"

import React, { useEffect, useState } from "react"
import { withPreview } from "gatsby-source-prismic"
// import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import BackgroundImage from "gatsby-background-image"

import SEO from "../components/seo"
import Layout from "../components/layout"
import BlogItem from "../components/blogitem"
import NewsletterItem from "../components/newsletteritem"
import ExternalItem from "../components/externalitem"
import Form from "../components/form"

import { ChevronCompactDown } from "react-bootstrap-icons"
import { Instagram, Linkedin } from "@icons-pack/react-simple-icons"

// import styles for preventing layout problems (classes missing) on initial page load
import "../styles/global.scss"
import UniversalLink from "../utils/UniversalLink"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import DynamicImage from "../utils/dynamicimage"

import cx from "classnames"

function Home({ data }) {
  // prevent elements from vanishing on changes
  // useEffect(() => {
  //   sal()
  // }, [])
  const [latestPostsJSX, setLatestPostsJSX] = useState([])

  const {
    hero_headline,
    hero_text,
    hero_background_image,
    hero_image,
    hero_two_columns_desktop,
    button_enabled,
    button_text,
    button_link,
    button_color,
    subscribe_text,
    about_me,
    say_hello,
    first_card_header,
    first_card_text,
    first_card_link,
    podcast_card_header,
    podcast_card_text,
  } = data.prismicHome.data

  const { prismicNewsletter, prismicPodcast } = data

  const latestPostsObject = [
    ...data.allPrismicExternalArticle.edges,
    ...data.allPrismicBlogPost.edges,
  ].sort((a, b) => {
    return new Date(a.node.data.date) > new Date(b.node.data.date) ? -1 : 1
  })

  useEffect(() => {
    setLatestPostsJSX(
      latestPostsObject?.slice(0, 3).map(({ node }) => {
        switch (node.__typename) {
          case "PrismicBlogPost":
            return (
              <BlogItem
                key={node.id}
                animation_active={false}
                background_color="bg-mbSnow"
                border_active={false}
                shadow_active={false}
                slug={node.uid}
                alt={node.data.main_image?.alt}
                image_src={node.data.main_image && node.data.main_image.src}
                image_fluid={
                  node.data.main_image && node.data.main_image.fluid
                    ? node.data.main_image.fluid
                    : null
                }
                node_id={node.data.id}
                tags={node.tags}
                title_text={node.data.title && node.data.title.text}
                excerpt_text={node.data.excerpt && node.data.excerpt.text}
                rounded_active={false}
                additionalClassNames="lg:w-1/3 lg:max-w-none min-w-full lg:min-w-0 sm:max-w-xs"
              />
            )

          case "PrismicExternalArticle":
            return (
              <ExternalItem
                key={node.data.article_link?.url}
                animation_active={false}
                rounded_active={false}
                shadow_active={false}
                background_color="bg-mbSnow"
                border_active={false}
                article_url={node.data.article_link?.url}
                alt={node.data.image?.alt}
                image_src={node.data.image?.url}
                image_fluid={
                  node.data.image?.fluid ? node.data.image.fluid : null
                }
                node_id={node.data.id}
                tags={node.tags}
                title_text={node.data.title?.text}
                excerpt_text={node.data.excerpt?.text}
                publishing_date={node.data.date}
                publisher={node.data.publisher}
                additionalClassNames="lg:w-1/3 lg:max-w-none min-w-full lg:min-w-0"
              />
            )
        }
      })
    )
  }, [])

  const latestIssueArray = data.allPrismicNewsletterIssue.edges.map(
    ({ node }) => {
      return (
        <NewsletterItem
          key={node.id}
          border_active={false}
          shadow_active={false}
          animations_active={false}
          // defaults to bg-white
          background_color="bg-mbSnow"
          slug={node.uid}
          alt={node.data.issue_title?.text}
          image_src={node.data.issue_image?.url}
          image_fluid={
            node.data.issue_image?.fluid ? node.data.issue_image.fluid : null
          }
          issue_link={node.data.issue_link?.url}
          publishing_date={node.data.publishing_date}
          node_id={node.data.id}
          title_text={node.data.issue_title?.text}
          excerpt_text={node.data.excerpt?.text}
          rounded_active={false}
          additionalClassNames="lg:w-1/3 lg:max-w-none min-w-full lg:min-w-0"
        />
      )
    }
  )

  // main layout
  return (
    <Layout data={data}>
      <div id="main-container">
        <SEO
          title={data.prismicHome.data.seo_title?.text}
          description={data.prismicHome.data.seo_description?.text}
        />
        {/* <Profiler id="BgIMAGEINDEX" onRender={actualDuration}> */}
        <BackgroundImage
          id="section-1"
          // className="min-h-screen"
          alt={data.prismicHome.data.header_image?.alt}
          loading="eager"
          // fadeIn={false}
          style={{ backgroundPosition: "top" }}
          // fluid={data.prismicHome.data.header_image.fluid}
          fluid={hero_background_image?.fluid}
          // fluid={
          //   data.prismicHome.data.header_image?.localFile?.childImageSharp
          //     ?.fluid
          // }
        >
          <div className="container flex justify-center mx-auto overflow-hidden">
            <div className="grid grid-rows-[0.3fr_0.6fr_0.1fr] md:grid-rows-[0.9fr_0.1fr] grid-cols-2 pt-8 sm:pt-10 md:pt-12  px-2 md:px-16">
              <div className="flex flex-col justify-center col-span-2 md:col-span-1 md:col-start-2">
                <div className="flex flex-col px-4 lg:px-0">
                  <h1
                    className="mt-4 text-4xl font-light leading-none text-center sm:text-start ld:mt-0 sm:leading-none lg:leading-none 2xl:leading-none 3xl:leading-none lg:text-6xl text-cb-900"
                    data-sal="fade"
                    data-sal-duration="1000"
                    data-sal-delay="150"
                    data-sal-easing="ease"
                  >
                    {hero_headline?.text}
                  </h1>
                  <div
                    className="mx-auto mt-4 text-base font-light text-justify text-gray-900 sm:text-start lg:mx-0 md:pt-4 sm:max-w-md lg:max-w-md post-html"
                    data-sal="fade"
                    data-sal-duration="1000"
                    data-sal-delay="350"
                    data-sal-easing="ease"
                    dangerouslySetInnerHTML={{
                      __html: hero_text.html,
                    }}
                  />
                  {button_enabled && (
                    <div className="flex justify-center w-full mt-3 sm:justify-start sm:mt-9 sm:w-auto">
                      <a
                        href={button_link?.url}
                        className={
                          "py-3 text-base text-center text-white transition-colors duration-500 rounded-md hover:bg-cb-800 lg:text-base px-4 bg-cb-500"
                        }
                        style={{
                          backgroundColor: button_color || "rgb(75, 156, 211)",
                        }}
                        target={button_link?.target}
                      >
                        {button_text?.text}
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="max-h-[50vh] xl:max-h-[65vh] col-span-2 mt-4 md:col-span-1 md:mt-0 2xl:max-h-[55vh] row-start-1 md:col-start-1">
                <DynamicImage
                  className="min-w-full min-h-full"
                  placeholderClassName="w-full object-cover object-center"
                  alt={"Hero image"}
                  style={{ maxHeight: "100%" }}
                  imgStyle={{
                    objectFit: "contain",
                    // width: "100%",
                    objectPosition: "50% 50%",
                    maxHeight: "100%",
                  }}
                  objectFit="contain"
                  src={hero_image?.url}
                  fluid={hero_image?.fluid}
                />
              </div>
              <div className="col-span-2">
                <div className="z-50 flex flex-col items-center justify-end flex-1 my-5">
                  <AnchorLink
                    to="/#section-2"
                    className="focus:outline-none"
                    aria-label="Jump down to section 2, a page overview organized in a card grid."
                  >
                    <ChevronCompactDown
                      className="w-8 h-8 transition-colors duration-500 ease-in-out text-cb-300 sm:w-10 sm:h-10 3xl:w-12 3xl:h-12 hover:text-cb-500 focus:outline-none"
                      size={96}
                      strokeWidth={0.5}
                      data-sal="fade"
                      data-sal-duration="2000"
                      data-sal-delay="150"
                      data-sal-easing="ease"
                      aria-label="Jump down to section 2, a page overview organized in a card grid."
                    />
                  </AnchorLink>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
        <div
          id="section-2"
          className="container flex items-center justify-center min-h-screen mx-auto section-2 bg-mbSnow"
        >
          <div className="grid w-full grid-flow-row-dense grid-cols-12 gap-2 px-2 py-1 mt-2 auto-rows-auto lg:grid-cols-10 lg:gap-3 lg:mt-0 md:py-8 md:px-16">
            <div
              className="col-span-12 row-span-1 lg:col-span-4 lg:row-span-2"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="325"
              data-sal-easing="ease"
            >
              <UniversalLink
                href={first_card_link?.url}
                target="_blank"
                rel="noreferrer"
              >
                <div className="h-full px-3 py-4 transition-colors duration-500 bg-white border border-black shadow-md navCard 4xl:px-10 4xl:py-12 lg:px-5 lg:py-6 hover:bg-gray-100">
                  <h2 className="mb-3 text-2xl italic leading-none sm:leading-none lg:leading-none 2xl:leading-none text-cb-900 lg:text-4xl">
                    {first_card_header.text}
                  </h2>
                  <p className="max-w-md text-sm font-thin text-gray-900 lg:text-base 2xl:text-lg">
                    {first_card_text.text}
                  </p>
                </div>
              </UniversalLink>
            </div>
            <div
              className="col-span-6 row-span-1 lg:col-span-3 lg:col-start-5 lg:col-end-8 lg:row-start-1 lg:row-end-2"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="350"
              data-sal-easing="ease"
            >
              <AnchorLink to="/#about" className="">
                <div className="flex flex-col items-center justify-center h-full px-5 py-4 transition-colors duration-500 bg-white border border-black shadow-md navCardSmall hover:bg-gray-100">
                  <h2 className="text-2xl leading-none sm:leading-none lg:leading-none 2xl:leading-none text-cb-900 lg:text-4xl">
                    About me
                  </h2>
                </div>
              </AnchorLink>
            </div>
            <div
              className="col-span-6 row-span-1 lg:col-span-3 lg:col-start-5 lg:col-end-8 lg:row-start-2 lg:row-end-3"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="550"
              data-sal-easing="ease"
            >
              <AnchorLink
                to="/#contact"
                className="col-span-6 row-span-1 lg:col-span-2"
              >
                <div className="flex flex-col items-center justify-center h-full px-5 py-4 transition-colors duration-500 bg-white border border-black shadow-md navCardSmall hover:bg-gray-100">
                  <h2 className="text-2xl leading-none sm:leading-none lg:leading-none 2xl:leading-none text-cb-900 lg:text-4xl">
                    Contact
                  </h2>
                </div>
              </AnchorLink>
            </div>
            <div
              className="col-span-12 row-span-1 lg:col-span-3 lg:row-span-2"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="325"
              data-sal-easing="ease"
            >
              <UniversalLink
                href="https://www.bettertravelpodcast.com/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="h-full px-3 py-4 transition-colors duration-500 bg-white border border-black shadow-md navCard 4xl:px-10 4xl:py-12 lg:px-5 lg:py-6 hover:bg-gray-100">
                  <h2 className="mb-3 text-2xl italic leading-none sm:leading-none lg:leading-none 2xl:leading-none text-cb-900 lg:text-4xl">
                    {podcast_card_header.text}
                  </h2>
                  <p className="max-w-md text-sm font-thin text-gray-900 lg:text-base 2xl:text-lg">
                    {podcast_card_text.text}
                  </p>
                </div>
              </UniversalLink>
            </div>
            <UniversalLink
              href={`${data.prismicHome.data.first_featured_article.document?.data.article_link.url}`}
              target="_blank"
              rel="noreferrer"
              className="col-span-12 row-span-1 sm:col-span-6 lg:row-span-2 lg:col-span-3"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="400"
              data-sal-easing="ease"
            >
              <div className="flex flex-col justify-start h-full px-3 py-4 transition-colors duration-500 bg-white border border-black shadow-md navCard 4xl:px-10 4xl:py-12 lg:px-5 lg:py-6 hover:bg-gray-100">
                <p className="mb-1 font-light leading-none tracking-wide sm:leading-none lg:leading-none 2xl:leading-none text-cb-500 text-xxs sm:text-xs 2xl:text-sm">
                  {data.prismicHome.data.first_featured_article.document &&
                    data.prismicHome.data.first_featured_article.document.data
                      .publisher &&
                    data.prismicHome.data.first_featured_article.document.data.publisher.toUpperCase()}
                </p>
                <h2 className="mb-2 text-lg leading-none sm:leading-none lg:leading-none 2xl:leading-none lg:mb-2 text-cb-900 lg:text-xl 2xl:text-2xl">
                  {data.prismicHome.data.first_featured_article.document &&
                    data.prismicHome.data.first_featured_article.document.data
                      .title &&
                    data.prismicHome.data.first_featured_article.document.data
                      .title.text}
                </h2>
                <p className="text-xs font-thin text-gray-900 lg:text-sm 2xl:text-base">
                  {data.prismicHome.data.first_featured_article.document &&
                    data.prismicHome.data.second_featured_article.document.data
                      .excerpt &&
                    data.prismicHome.data.first_featured_article.document.data
                      .excerpt.text}
                </p>
              </div>
            </UniversalLink>
            <div
              className="col-span-6 row-span-1 lg:col-span-3 lg:row-span-2"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="475"
              data-sal-easing="ease"
            >
              <UniversalLink
                href={`${data.prismicHome.data.third_featured_content.document.data.article_link.url}`}
                target="_blank"
                rel="noreferrer"
                className="col-span-12 row-span-1 sm:col-span-6 lg:row-span-2 lg:col-span-3"
              >
                <div className="flex flex-col justify-start h-full px-3 py-4 transition-colors duration-500 bg-white border border-black shadow-md navCard 4xl:px-10 4xl:py-12 lg:px-5 lg:py-6 hover:bg-gray-100">
                  <p className="mb-1 font-light leading-none tracking-wide sm:leading-none lg:leading-none 2xl:leading-none text-cb-500 text-xxs sm:text-xs 2xl:text-sm">
                    {data.prismicHome.data.third_featured_content.document &&
                      data.prismicHome.data.third_featured_content.document.data
                        ?.publisher &&
                      data.prismicHome.data.third_featured_content.document.data.publisher?.toUpperCase()}
                  </p>
                  <h2 className="mb-2 text-lg leading-none sm:leading-none lg:leading-none 2xl:leading-none lg:mb-2 text-cb-900 lg:text-xl 2xl:text-2xl">
                    {data.prismicHome.data.third_featured_content.document &&
                      data.prismicHome.data.third_featured_content.document.data
                        ?.title &&
                      data.prismicHome.data.third_featured_content.document.data
                        .title?.text}
                  </h2>
                  <p className="text-xs font-thin text-gray-900 lg:text-sm 2xl:text-base">
                    {data.prismicHome.data.third_featured_content.document &&
                      data.prismicHome.data.third_featured_content.document.data
                        ?.excerpt &&
                      data.prismicHome.data.third_featured_content.document.data
                        .excerpt?.text}
                  </p>
                </div>
              </UniversalLink>
            </div>
            <div
              className="col-span-6 row-span-1 lg:col-span-4 lg:row-span-2"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="425"
              data-sal-easing="ease"
            >
              <div className="flex flex-col h-full px-3 py-4 transition-colors duration-500 bg-white border border-black shadow-md navCard 4xl:px-10 4xl:py-12 lg:px-5 lg:py-6">
                <h2 className="mb-3 text-2xl italic leading-none sm:leading-none lg:leading-none 2xl:leading-none text-cb-900 lg:text-4xl">
                  Follow Along
                </h2>
                <div className="max-w-md text-sm font-thin text-gray-900 lg:text-base 2xl:text-lg">
                  <p className="">Find me on other platforms:</p>
                  <ul className="flex flex-col w-full gap-2 pt-2 ml-2 lg:gap-3">
                    <li className="transition-colors duration-300 group">
                      <UniversalLink
                        href="https://www.instagram.com/paigemcclanahan/"
                        className="flex items-center w-full h-full"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Instagram className="w-4 h-4 text-gray-500 transition-colors duration-150 lg:w-5 lg:h-5 group-hover:text-cb-500" />
                        <span className="ml-2 text-sm font-thin text-gray-900 lg:text-base 3xl:text-lg">
                          Instagram
                        </span>
                      </UniversalLink>
                    </li>
                    <li className="transition-colors duration-300 group">
                      <UniversalLink
                        href="https://www.linkedin.com/in/paige-mcclanahan-282793a/"
                        className="flex items-center w-full h-full"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Linkedin className="w-4 h-4 text-gray-500 transition-colors duration-150 lg:w-5 lg:h-5 group-hover:text-cb-500" />
                        <span className="ml-2 text-sm font-thin text-gray-900 lg:text-base 3xl:text-lg">
                          LinkedIn
                        </span>
                      </UniversalLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="col-span-6 row-span-1 lg:col-span-5"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="500"
              data-sal-easing="ease"
            >
              <AniLink
                cover
                direction="left"
                bg="#FFFBFA"
                to="/clips"
                className=""
              >
                <div className="h-full px-3 py-4 transition-colors duration-500 bg-white border border-black shadow-md navCard 4xl:px-10 4xl:py-12 lg:px-5 lg:py-6 hover:bg-gray-100">
                  <h2 className="mb-3 text-2xl italic leading-none sm:leading-none lg:leading-none 2xl:leading-none text-cb-900 lg:text-4xl">
                    Clips
                  </h2>
                  <p className="max-w-md text-sm font-thin text-gray-900 lg:text-base 2xl:text-lg">
                    A selection of my recent writing
                  </p>
                </div>
              </AniLink>
            </div>

            <div
              className="col-span-6 row-span-1 lg:col-span-5"
              data-sal="slide-up"
              data-sal-duration="1000"
              data-sal-delay="575"
              data-sal-easing="ease"
            >
              <AniLink
                cover
                direction="left"
                bg="#FFFBFA"
                to="/newsletter"
                className=""
              >
                <div className="h-full px-3 py-4 transition-colors duration-500 bg-white border border-black shadow-md navCard 4xl:px-10 4xl:py-12 lg:px-5 lg:py-6 hover:bg-gray-100">
                  <h2 className="mb-3 text-2xl italic leading-none sm:leading-none lg:leading-none 2xl:leading-none text-cb-900 lg:text-4xl">
                    {prismicNewsletter.data.headline.text}
                  </h2>
                  <p className="max-w-md text-sm font-thin text-gray-900 lg:text-base 2xl:text-lg">
                    {prismicNewsletter.data.text.text}
                  </p>
                </div>
              </AniLink>
            </div>
          </div>
        </div>
        <div
          id="about"
          className="container grid grid-cols-12 pt-10 pb-16 bg-mbSnow lg:pb-32 grid-auto-rows 2xl:px-32"
        >
          <div
            className="flex flex-col items-center justify-center w-full col-start-1 col-end-13 row-start-1 row-end-1 lg:flex-row"
            // GRID className="grid grid-cols-12 col-start-2 col-end-12 row-start-1 row-end-1 mx-2"

            data-sal="fade"
            data-sal-duration="1500"
            data-sal-delay="100"
            data-sal-easing="ease"
          >
            <BackgroundImage
              id="header-avatar-container"
              // GRID className="flex items-center justify-center object-cover w-full col-start-1 col-end-13 row-start-1 mt-2 overflow-hidden rounded-full shadow-inner lg:col-start-1 lg:col-end-8"
              className="flex items-center justify-center object-cover mt-2 overflow-hidden rounded-full shadow-inner lg:col-start-1 lg:col-end-8"
              imgStyle={{ width: "100%", height: "100%" }}
              alt={data.prismicHome.data.portrait?.alt}
              // style={{ backgroundPosition: "top" }}
              // fluid={data.prismicHome.data.portrait.fluid}
              // fluid={data.localPortrait?.childImageSharp?.fluid}
              fluid={
                data.prismicHome.data.portrait?.localFile?.childImageSharp
                  ?.fluid
              }
            />
            {/* <img className="object-cover w-full h-full" src={Portrait}></img> */}
            <div className="flex flex-col items-center justify-center w-full col-start-1 col-end-13 row-start-2 px-5 pt-2 lg:w-1/2 lg:items-baseline lg:col-start-8 lg:row-start-1 lg:pt-0 lg:py-12 2xl:max-w-2xl lg:ml-10 md:max-w-lg">
              <h2 className="text-3xl leading-tight text-center sm:leading-tight lg:leading-tight sm:text-4xl lg:text-4xl lg:text-left">
                About Me
              </h2>
              <p className="mt-3 text-sm font-light text-justify text-gray-900 lg:mt-1 lg:text-left lg:text-base 2xl:text-lg">
                {about_me.text}
              </p>
            </div>
          </div>
        </div>
        <div
          id="contact"
          className="px-2 py-10 pb-16 lg:px-20 lg:py-20 lg:pb-32 bg-bs-200"
        >
          <div className="px-5 sm:px-10 xl:px-32">
            <h2 className="text-3xl text-center sm:text-4xl lg:text-4xl">
              Say Hello
            </h2>
            <div className="flex flex-col items-center w-full mt-3 lg:flex-row lg:justify-around 2xl:justify-around 3xl:w-1/2 3xl:mx-auto lg:mt-6">
              <div className="pb-5 md:w-3/4 lg:pr-16 lg:pb-0 2xl:max-w-2xl">
                <p className="text-sm font-light text-justify lg:text-base lg:text-left 2xl:text-lg">
                  {say_hello.text}
                </p>
              </div>
              <div className="flex items-center justify-center px-2 py-8 bg-white border border-black shadow-md lg:w-1/2 sm:px-5">
                <div className="flex">
                  <UniversalLink
                    className="text-base transition-colors duration-300 sm:text-lg 3xl:text-2xl hover:text-cb-700"
                    href="mailto:hello@paigemcclanahan.com"
                  >
                    hello@paigemcclanahan.com
                  </UniversalLink>
                  {/* <svg className="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.5} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" /></svg> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-5 py-10 bg-mbSnow lg:pb-32">
          <div className="flex flex-row items-center justify-around py-3 lg:py-10">
            <hr className="flex-auto mr-1 border-gray-500 sm:mr-3" />
            <h3 className="text-xl text-center text-gray-700 lg:text-2xl">
              Latest Clips
            </h3>
            <hr className="flex-auto ml-1 border-gray-500 sm:ml-3" />
          </div>
          <div className="flex flex-col items-center justify-around lg:items-stretch lg:gap-5 lg:flex-row md:mx-32 lg:mx-0 3xl:mx-auto sm:mx-20">
            {latestPostsJSX}
          </div>
          <div className="flex justify-around mt-0 mb-5 lg:mt-12 lg:mb-16">
            <AniLink to="/clips" cover direction="left" bg="#FFFBFA">
              <div className="flex items-center px-5 py-4 text-gray-700 group">
                <span className="transition-colors duration-500 group-hover:text-gray-900">
                  All Clips
                </span>
                <svg
                  className="w-8 h-8 ml-2 transition-colors transition-transform duration-500 transform 3xl:w-10 3xl:h-10 text-cb-900 group-hover:text-cb-500 group-hover:translate-x-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={0.8}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </div>
            </AniLink>
          </div>
          <div className="flex flex-row items-center justify-around py-3 lg:py-10">
            <hr className="flex-auto mr-1 border-gray-500 sm:mr-3" />
            <h3 className="text-xl text-center text-gray-700 lg:text-2xl">
              Latest Newsletter Issues
            </h3>
            <hr className="flex-auto ml-1 border-gray-500 sm:ml-3" />
          </div>
          <div className="flex flex-col items-center justify-around lg:items-stretch lg:gap-5 lg:flex-row sm:mx-20 md:mx-32 lg:mx-0 3xl:mx-auto">
            {latestIssueArray}
          </div>
          <div className="flex justify-around mt-0 mb-5 lg:mt-12 lg:mb-5">
            <AniLink to="/newsletter" cover direction="left" bg="#FFFBFA">
              <div className="flex items-center px-5 py-4 text-gray-700 group">
                <span className="transition-colors duration-500 group-hover:text-gray-900">
                  All Newsletter Issues
                </span>
                <svg
                  className="w-8 h-8 ml-2 transition-colors transition-transform duration-500 transform 3xl:w-10 3xl:h-10 text-cb-900 group-hover:text-cb-500 group-hover:translate-x-3"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={0.8}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </div>
            </AniLink>
          </div>
        </div>
        <div className="container flex flex-col items-center col-start-2 col-end-12 row-start-1 row-end-4 px-5 mb-10 md:mb-24 lg:mb-32 bg-mbSnow">
          <p className="max-w-md text-base font-light text-justify text-gray-800 lg:max-w-2xl lg:text-center lg:text-lg">
            {subscribe_text.text}
          </p>
          <Form />
        </div>
      </div>
    </Layout>
  )
}

export default withPreview(Home)

export const query = graphql`
  query {
    localHeader: file(relativePath: { eq: "images/mont_blanc/mb5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    prismicSiteSettings {
      data {
        cookie_banner_text {
          text
        }
        main_menu_links {
          text {
            text
          }
          link {
            url
          }
        }
      }
    }
    prismicHome {
      data {
        hero_headline {
          text
        }
        hero_text {
          text
          html
        }
        hero_background_image {
          alt
          url
          fluid(maxWidth: 1440) {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_image {
          alt
          url
          fluid(maxWidth: 1440) {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_two_columns_desktop
        subscribe_text {
          text
        }
        button_enabled
        button_text {
          text
        }
        button_link {
          url
          target
        }
        about_me {
          text
        }
        say_hello {
          text
        }
        first_card_header {
          text
        }
        first_card_text {
          text
        }
        first_card_link {
          url
          target
        }
        podcast_card_header {
          text
        }
        podcast_card_text {
          text
        }
        portrait {
          alt
          localFile {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        email_share_subject {
          text
        }
        email_share_body {
          text
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        first_featured_article {
          document {
            __typename
            ... on PrismicPodcastEpisode {
              data {
                publisher
                title {
                  text
                }
                article_link {
                  url
                }
                excerpt {
                  text
                }
              }
            }
            ... on PrismicExternalArticle {
              data {
                publisher
                title {
                  text
                }
                article_link {
                  url
                }
                excerpt {
                  text
                }
              }
            }
          }
        }
        second_featured_article {
          document {
            __typename
            ... on PrismicPodcastEpisode {
              data {
                publisher
                title {
                  text
                }
                article_link {
                  url
                }
                excerpt {
                  text
                }
              }
            }
            ... on PrismicExternalArticle {
              data {
                publisher
                title {
                  text
                }
                article_link {
                  url
                }
                excerpt {
                  text
                }
              }
            }
          }
        }
        third_featured_content {
          document {
            __typename
            ... on PrismicExternalArticle {
              data {
                publisher
                title {
                  text
                }
                article_link {
                  url
                }
                excerpt {
                  text
                }
              }
            }
            ... on PrismicPodcastEpisode {
              data {
                publisher
                title {
                  text
                }
                article_link {
                  url
                }
                excerpt {
                  text
                }
              }
            }
          }
        }
      }
    }
    prismicBlog {
      data {
        headline {
          text
        }
        text {
          text
        }
      }
    }

    prismicNewsletter {
      data {
        headline {
          text
        }
        text {
          text
        }
      }
    }
    allPrismicBlogPost(sort: { order: DESC, fields: data___date }, limit: 3) {
      edges {
        node {
          __typename
          id
          uid
          tags
          data {
            title {
              text
            }
            excerpt {
              text
            }
            date(formatString: "DD.MM.YYYY")
            main_image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
    allPrismicExternalArticle {
      edges {
        node {
          __typename
          id
          tags
          data {
            publisher
            date
            title {
              text
            }
            excerpt {
              text
            }
            article_link {
              url
            }
            image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
    allPrismicPodcastEpisode {
      edges {
        node {
          __typename
          id
          tags
          data {
            publisher
            date
            title {
              text
            }
            excerpt {
              text
            }
            article_link {
              url
            }
            image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }

    allPrismicNewsletterIssue(
      sort: { order: DESC, fields: data___publishing_date }
      limit: 3
    ) {
      edges {
        node {
          __typename
          uid
          id
          data {
            issue_title {
              text
            }
            excerpt {
              text
            }
            publishing_date(formatString: "DD.MM.YYYY")
            issue_link {
              url
            }
            issue_image {
              alt
              url
              fluid(maxWidth: 400) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`
