import React from "react"
import { Link } from "gatsby"

const UniversalLink = props => {
  const {
    className,
    children,
    cover,
    to,
    ariaLabel,
    disabled,
    href,
    direction,
    bg,
    ...rest
  } = props
  const internal = to && !href

  const isAniLinkWarning = cover || direction || bg

  if (isAniLinkWarning) {
    console.warn(
      `AniLink prop found. Please check whether AniLink should be used and hard-coded.`
    )
  }

  return internal ? (
    <Link className={className} to={to} {...rest}>
      {children}
    </Link>
  ) : (
    <a href={href} className={className} {...rest}>
      {children}
    </a>
  )
}

export default UniversalLink
